
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code3 } from "@/views/resources/documentation/element-ui/basic/link/code";

export default defineComponent({
  name: "underline",
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code3
    };
  }
});
