
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasic from "@/views/resources/documentation/element-ui/basic/link/Basic.vue";
import EUIDisabled from "@/views/resources/documentation/element-ui/basic/link/Disabled.vue";
import EUIUnderline from "@/views/resources/documentation/element-ui/basic/link/Underline.vue";
import EUIIcon from "@/views/resources/documentation/element-ui/basic/link/Icon.vue";

export default defineComponent({
  name: "layout",
  components: {
    EUIBasic,
    EUIDisabled,
    EUIUnderline,
    EUIIcon
  },
  setup() {
    setCurrentPageTitle("Link");
  }
});
